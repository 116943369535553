import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {fetchCompanies, fetchProduct, resetProductEditData} from './slice';
import Spinner from '../../../components/Spinner';
import apiClient from '../../../store/apiClient';
import CommonInput from '../../../components/CommonInput';
import {compareColorSchemes, DEF_VATS} from '../../Product/slice';
import {genDefPrices} from '../../../components/ProductEditModals/PriceModal';
import {priceCeil, priceFloor, priceRound} from '../../../services/price';
import {DEF_COLOR_SCHEMES} from '../../../services/utils';

const ProductPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const {loading, data, companies}: {loading: boolean, data?: IProduct, companies: ICompany[]} = useAppSelector((state) => state.adminProduct);
    const merchant = useAppSelector((state) => state.app.merchant!);

    const [product, setProduct] = useState<IProduct>();
    const [prices, setPrices] = useState<string[]>([]);
    const [tmpPrice, setTmpPrice] = useState<string>('');
    const [defPrice, setDefPrice] = useState<number>(0);

    useEffect(() => {
        dispatch(fetchProduct(+(id || 0)));
        dispatch(fetchCompanies());

        return () => {
            dispatch(resetProductEditData());
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            const tmpPrices = genDefPrices(data.prices || undefined);
            setProduct(data);
            setPrices(tmpPrices);
            setTmpPrice(`${data.totalPrice}`);
            setDefPrice(tmpPrices.findIndex((i) => i === `${+data.productPrice}`));
        }
    }, [data]);

    useEffect(() => {
        if (product) handlePrices(product.totalPrice, prices, +prices[defPrice], product.taxClass);
    }, [product?.type]);

    const handleChanges = (changes: Partial<IProduct>) => {
        setProduct({
            ...product!,
            ...changes,
        });
    };

    const handlePrices = (price: number, prices: string[], defPrice: number, taxClass: number) => {
        const changes: Partial<IProduct> = {
            taxClass: taxClass,
        };

        const taxPercent = DEF_VATS[taxClass] / 100;

        if (product!.type === 'voucher') {
            changes.prices = prices.filter(Boolean).join('|');
            changes.productPrice = defPrice;
            changes.totalPrice = priceCeil(changes.productPrice! * (1 + +merchant.sirupayFee));
        } else {
            changes.prices = null;
            changes.totalPrice = priceRound(price);
            changes.productPrice = priceRound(changes.totalPrice / (1 + +merchant.serviceFee));
        }

        changes.serviceFee = priceRound(changes.totalPrice - changes.productPrice);
        changes.basePrice = priceFloor(changes.totalPrice / (1 + taxPercent));
        changes.taxValue = priceRound(changes.totalPrice - changes.basePrice);

        setProduct({
            ...product!,
            ...changes,
        });
    };

    const handleFormSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!product?.description.replace(/<[^>]+>/g, '') || (product.type === 'coupon' && !!product.tpShowInstructions && !product.tpSuccessInstructionsText.replace(/<[^>]+>/g, ''))) {
            return false;
        }

        await apiClient.saveProduct({
            ...product,
            qrCode: null,
        });
        navigate('../');
    };

    return <>
        {loading && <Spinner/>}

        {!product ? null : <form className="admin-form" onSubmit={handleFormSubmit}>
            <CommonInput
                type={'select'}
                label={t('product.type')}
                value={product.type}
                onChange={(e) => handleChanges({type: e.target.value})}
                options={[
                    {value: 'coupon', name: t('product.coupon')},
                    {value: 'ticket', name: t('product.ticket')},
                    {value: 'voucher', name: t('product.voucher')},
                ]}
            />
            <CommonInput
                type={'select'}
                label={t('product.company')}
                value={product.companyId || ''}
                onChange={(e) => handleChanges({companyId: +e.target.value || undefined})}
                options={[
                    {value: '', name: t('select.noCompany')},
                    ...companies.map((i) => ({value: i.id, name: i.name})),
                ]}
            />

            <CommonInput
                label={t('product.name')}
                required={true}
                value={product.name}
                pattern=".*\w+.*"
                hint={t('product.hint.url', {url: product.urlName})}
                onChange={(e) => handleChanges(id && id !== '0' ? {
                    name: e.target.value
                } : {
                    name: e.target.value,
                    urlName: e.target.value.toLowerCase().split(/[^a-z0-9]+/g).filter(Boolean).join('-')
                })}
            />

            <CommonInput
                type={'htmlarea'}
                label={t('product.description')}
                required={true}
                value={product.description}
                onChange={(e) => handleChanges({description: e.target.value})}
            />

            <label className="inputLabel">
                <p className="colorOptions">
                    {DEF_COLOR_SCHEMES.map(
                        (i, k) => <span
                            key={k}
                            style={{borderColor: `#${i.uiMajorColor}`, backgroundColor: `#${i.uiMinorColor}`}}
                            className={compareColorSchemes(i, product) ? 'sel' : ''}
                            onClick={() => handleChanges(i)}
                        />,
                    )}
                </p>
                <span>{t('product.colorScheme')}</span>
            </label>
            <CommonInput
                label={t('product.color.main')}
                required={true}
                value={product.uiMajorColor}
                onChange={(e) => handleChanges({uiMajorColor: e.target.value})}
                pattern="[0-9a-fA-F]{6}"
            />
            <CommonInput
                label={t('product.color.secondary')}
                required={true}
                value={product.uiMinorColor}
                onChange={(e) => handleChanges({uiMinorColor: e.target.value})}
                pattern="[0-9a-fA-F]{6}"
            />
            <CommonInput
                label={t('product.color.mainText')}
                required={true}
                value={product.uiMajorTextColor}
                onChange={(e) => handleChanges({uiMajorTextColor: e.target.value})}
                pattern="[0-9a-fA-F]{6}"
            />
            <CommonInput
                label={t('product.color.secondaryText')}
                required={true}
                value={product.uiMinorTextColor}
                onChange={(e) => handleChanges({uiMinorTextColor: e.target.value})}
                pattern="[0-9a-fA-F]{6}"
            />

            <CommonInput
                type={'select'}
                label={t('product.vat')}
                value={product.taxClass}
                // onChange={(e) => handleChanges({taxClass: e.target.value})}
                onChange={(e) => handlePrices(product.totalPrice, prices, +prices[defPrice], +e.target.value)}
                options={DEF_VATS.filter(Boolean).map((i, k) => ({
                    value: k + 1, name: `${i}%`
                }))}
            />

            {product.type === 'voucher' ? <>
                {prices.map((i, k) => <CommonInput
                    key={k}
                    label={<label className="cb toggle"><input
                        type="radio"
                        value={k}
                        checked={defPrice === +k}
                        onChange={() => {
                            setDefPrice(+k);
                            handlePrices(product.totalPrice, prices, +prices[+k], product.taxClass);
                        }}
                    /><span>{t('product.price')} {k + 1}</span></label>}
                    value={i}
                    required={defPrice === k}
                    onChange={(e) => {
                        const newPrices: string[] = JSON.parse(JSON.stringify(prices));
                        newPrices[k] = e.target.value;
                        setPrices(newPrices);
                        handlePrices(product.totalPrice, newPrices, +prices[defPrice], product.taxClass);
                    }}
                    pattern="([0-9]|[1-9][0-9]+)(\.[0-9]{1,2})?"
                />)}
            </> : <>
                <CommonInput
                    label={t('product.price')}
                    required={true}
                    value={tmpPrice}
                    onChange={(e) => {
                        setTmpPrice(e.target.value);

                        if (!Number.isNaN(+e.target.value)) {
                            handlePrices(+e.target.value, prices, +prices[defPrice], product.taxClass);
                            // handleChanges({totalPrice: +e.target.value});
                        }
                    }}
                    hint={t('product.hint.price', {total: product.totalPrice, product: product.productPrice, fee: product.serviceFee})}
                    pattern="([0-9]|[1-9][0-9]+)(\.[0-9]{1,2})?"
                />
            </>}

            {product.type === 'coupon' && <>
                <CommonInput
                    label={t('product.success.title')}
                    required={true}
                    value={product.tpSuccessTitle}
                    onChange={(e) => handleChanges({tpSuccessTitle: e.target.value})}
                />

                <CommonInput
                    label={t('product.success.text')}
                    required={true}
                    value={product.tpSuccessText}
                    onChange={(e) => handleChanges({tpSuccessText: e.target.value})}
                />

                <CommonInput
                    type={'select'}
                    label={t('coupon.shareWithFriend')}
                    value={product.tpShowShare}
                    onChange={(e) => handleChanges({tpShowShare: +e.target.value as Flag})}
                    options={[
                        {value: 0, name: t('select.no')},
                        {value: 1, name: t('select.yes')},
                    ]}
                />

                <CommonInput
                    type={'select'}
                    label={t('coupon.receipt')}
                    value={product.tpShowReceipt}
                    onChange={(e) => handleChanges({tpShowReceipt: +e.target.value as Flag})}
                    options={[
                        {value: 0, name: t('select.no')},
                        {value: 1, name: t('select.yes')},
                    ]}
                />

                <CommonInput
                    type={'select'}
                    label={t('coupon.useCode')}
                    value={product.tpShowInstructions}
                    onChange={(e) => handleChanges({tpShowInstructions: +e.target.value as Flag})}
                    options={[
                        {value: 0, name: t('select.no')},
                        {value: 1, name: t('select.yes')},
                    ]}
                />

                {!!product.tpShowInstructions && <>
                    <CommonInput
                        type={'htmlarea'}
                        label={t('product.instructions.text')}
                        required={true}
                        value={product.tpSuccessInstructionsText}
                        onChange={(e) => handleChanges({tpSuccessInstructionsText: e.target.value})}
                    />

                    <CommonInput
                        label={t('product.instructions.button')}
                        required={true}
                        value={product.tpSuccessInstructionsButton}
                        onChange={(e) => handleChanges({tpSuccessInstructionsButton: e.target.value})}
                    />
                </>}

                <CommonInput
                    label={t('product.deliveryURL')}
                    value={product.deliveryUrl}
                    onChange={(e) => handleChanges({deliveryUrl: e.target.value || null})}
                    pattern="^https?://[^/]+\.[a-zA-Z0-9]+(/.*)?"
                />

            </>}

            {product.type === 'ticket' && <CommonInput
                label={t('product.redirectURL')}
                value={product.redirectUrl}
                onChange={(e) => handleChanges({redirectUrl: e.target.value || null})}
                pattern="^https?://[^/]+\.[a-zA-Z0-9]+(/.*)?"
            />}

            <CommonInput
                type={'textarea'}
                label={t('product.smsText')}
                required={true}
                value={product.smsText}
                onChange={(e) => handleChanges({smsText: e.target.value})}
            />

            <CommonInput
                type={'phone'}
                label={t('product.phone')}
                value={product.phoneNumber}
                hint={'Product owner will receive the SMS about each payment result to this number'}
                onChange={(e) => handleChanges({phoneNumber: e.target.value || null})}
            />

            <CommonInput
                type={'select'}
                label={t('product.showServiceFee')}
                value={product.showServiceFee}
                onChange={(e) => handleChanges({showServiceFee: +e.target.value as Flag})}
                options={[
                    {value: 0, name: t('select.no')},
                    {value: 1, name: t('select.yes')},
                ]}
            />

            <CommonInput
                type={'select'}
                label={t('product.hidden')}
                value={product.isHidden}
                onChange={(e) => handleChanges({isHidden: +e.target.value as Flag})}
                options={[
                    {value: 0, name: t('select.no')},
                    {value: 1, name: t('select.yes')},
                ]}
            />

            <CommonInput
                type={'select'}
                label={t('product.published')}
                value={product.isPublished}
                onChange={(e) => handleChanges({isPublished: +e.target.value as Flag})}
                options={[
                    {value: 0, name: t('select.no')},
                    {value: 1, name: t('select.yes')},
                ]}
            />

            <div className="buttons-wrap">
                <button type="button" className="main-button size-normal cancel-button" onClick={() => navigate('../')}>{t('button.cancel')}</button>

                <button type="submit" className="main-button size-normal">{t('button.save')}</button>
            </div>
        </form>}
    </>;
};

export default ProductPage;